import gql from 'graphql-tag'

export const ADD_VIEWS_VACANCY = gql`
  mutation AddViewsVacancy($id: ID!) {
    addViewsVacancy(id: $id)
  }
`

export const APPLY_MUTATION = gql`
  mutation Apply($vacancyId: ID!) {
    apply(vacancyId: $vacancyId)
  }
`
export const SEND_CANDIDANCE_NOTIFICATION = gql `
  mutation addCandidateNotify($candidateId:ID,$entity:ID,$idType:String,$type:String,$entityName:String,$date:String,$description:String) {
    addCandidateNotify(candidateId:$candidateId,entity:$entity,idType:$idType,type:$type,entityName:$entityName,date:$date,description:$description){
      candidate{
        name
      }
      content{
        description
      }
    }
  }
`
export const SEND_UNSOLICITED_CANDIDANCY = gql `
  mutation unsolicitedCandidancy($unsolicitedInput:unsolicitedInput) {
    unsolicitedCandidancy(unsolicitedInput:$unsolicitedInput) {
      jobType
    }
  }
`