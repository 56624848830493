<template>
    <v-sheet class="py-3 px-0" v-if="vacancy" elevetion="0">
    <!-- <pre v-for="cand in vacancy.candidacies" :key="cand">
      {{ currentUser.candidate.id }}
      >>>>{{ cand.candidate.id ===currentUser.candidate.id }}</pre
    > -->
    <!-- <pre>
      {{vacancy.candidacies}}
    </pre> -->
    <v-row class="d-flex flex-row justify-space-between align-center px-0 my-3">
      <div class="mx-0">
        <v-card-title class="text-h5" v-text="vacancy.title"></v-card-title>
      </div>
    </v-row>
    <v-row class="d-flex flex-row justify-space-between align-center px-3 my-3">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div class="text--secondary body-2" v-if="vacancy.locale">
          <v-btn x-small color="primary" dark class="mx-0">
            {{ vacancy.type }}
          </v-btn>
          <v-btn x-small color="secondary" dark class="mx-2">
            {{ vacancy.category }}
          </v-btn>
        </div>
      </div>
    </v-row>
    <v-divider></v-divider>
    <div class="py-4" v-if="vacancy.entity">
      <v-row class="d-flex flex-row align-center">
        <!-- <pre>
          {{apply_cand}}
        </pre> -->
        <v-list-item>
          <v-list-item-avatar color="grey">
            <img
              :src="
                vacancy.entity.logo
                  ? `${apiUrl}/images/entity/${vacancy.entity.logo}`
                  : `/avatar-whire.png`
              "
              alt=""
            />
          </v-list-item-avatar>
          <v-list-item-content class="">
            <v-list-item-title class="black--text" v-if="vacancy.entity">
              {{ vacancy.entity.name ? vacancy.entity.name : "Instituicao" }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-html="
                formatDate(vacancy.finalDate) === 'Expirado ' ||
                formatDate(vacancy.finalDate) === 'Expired '
                  ? `<span class=\'subtitle\' style=\'color:red\'>${this.$t(
                      'expired'
                    )}</span>`
                  : formatDate(vacancy.finalDate)
              "
            >
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <v-btn
                :href="`//${vacancy.entity.media.facebook}`"
                target="_blank"
                icon
              >
                <v-icon small color="primary">mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                :href="`//${vacancy.entity.media.linkedin}`"
                target="_blank"
                icon
              >
                <v-icon small color="primary">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn
                :href="`//${vacancy.entity.media.twitter}`"
                target="_blank"
                icon
              >
                <v-icon small color="primary">mdi-twitter</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
        <v-row class="d-flex flex-row align-center">
          <p
            class="mx-7 mt-4 text-justify subtitle-2 display-4"
            style="color: rgba(0, 0, 0, 0.6)"
          >
            {{ vacancy.entity.about }}
          </p>
        </v-row>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="my-8 px-3">
      <v-row class="d-flex flex-row align-center">
        <div><v-icon left small>place</v-icon></div>
        <div
          class="text-caption mr-1"
          v-for="place in vacancy.place"
          :key="place"
        >
          {{ place }},
        </div>
        <v-spacer></v-spacer>
        <div>
          <!-- <pre>
           {{apply_cand}}
         </pre> -->
          <v-btn
            :disabled="
              formatDate(vacancy.finalDate) === 'Expirado ' ||
              formatDate(vacancy.finalDate) === 'Expired ' ||
              appliedFor ||
              apply_cand
            "
            tile
            color="primary"
            class="body-2 text-uppercase px-2"
            @click.stop="apply()"
            >{{ vacancy.locale === "pt" ? "Candidatar-se" : "Apply" }}
          </v-btn>
        </div>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="pt-8 mb-6 black--text">
      <div
        class="text--secondary body-2 black--text"
        v-html="vacancy.description"
      ></div>
    </div>

    <!-- <v-list-item  class="d-flex flex-row justify-space-between align-center px-0 my-3">
      <v-list-item-content>
        <v-list-item-title class="text-left text-h6  black--text">Requisitos obrigatórios</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <v-list-item
      two-line
      class="d-flex flex-row justify-space-between align-center px-0"
    >
      <v-list-item-content>
        <v-list-item-title class="black--text mb-3">
          {{
            getPublishingLang(vacancy.locale).academicEducation
          }}</v-list-item-title
        >
        <v-list-item-subtitle
          class="subtitle mb-2"
          v-for="formation in vacancy.formation"
          :key="formation.course"
        >
          {{ getCourse(formation.course) }} -
          {{ getCourseLevel(formation.level) }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      two-line
      class="d-flex flex-row justify-space-between align-center px-0 my-2"
    >
      <v-list-item-content>
        <v-list-item-title class="black--text mb-3">
          {{ getPublishingLang(vacancy.locale).languages }}</v-list-item-title
        >
        <v-list-item-subtitle
          class="subtitle mb-2"
          v-for="language in vacancy.languages"
          :key="language.name"
        >
          {{ language.name }} -
          {{ getLanguageLevel(language.level) }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      two-line
      class="d-flex flex-row justify-space-between align-center px-0 my-2"
    >
      <v-list-item-content>
        <v-list-item-title class="black--text mb-3">
          {{
            getPublishingLang(vacancy.locale).professionalExperience
          }}</v-list-item-title
        >
        <v-list-item-subtitle
          class="subtitle"
          v-for="(professionalExp, index) in vacancy.professionalExperience"
          :key="index"
        >
          {{ professionalExp.yearsExperience }}
          {{
            professionalExp.yearsExperience > 1
              ? getPublishingLang(vacancy.locale).years
              : getPublishingLang(vacancy.locale).year
          }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <alert-dialog
      :dialog="dialogAlert"
      :message="completeMessage"
      @close="closeAlert()"
      @go="go()"
    />

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-sheet>
</template>

<script>
import { API_URL } from "@/api";
import {
  APPLY_MUTATION,
  SEND_CANDIDANCE_NOTIFICATION,
} from "./../graphql/Mutation";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
import ProgressDialog from "./../../../components/ProgressDialog.vue";
import SuccessDialog from "./../../../components/SuccessDialog.vue";
import { statusVacancyMixins } from "@/mixins/status";
import { ADD_DIALOG } from "@/mixins/dialog";
import moment from "moment";
import AlertDialog from "../../../../entity/components/AlertDialog.vue";
export default {
  name: "DetailsCard",
  components: { ErrorDialog, ProgressDialog, SuccessDialog, AlertDialog },
  props: {
    vacancy: Object,
    apply_cand: Boolean,
  },
  mixins: [statusVacancyMixins, ADD_DIALOG],
  data: () => ({
    apiUrl: API_URL,
    appliedFor: false,
    dialogAlert: false,
    done: "false",
    completeMessage: "",
    fields: {
      name: "",
      lastName: "",
      email: "",
      telephone: "",
      address: "",
      genre: "",
      dateBirth: "",
      nationality: "",
    },
  }),
  created() {
    this.done = this.$route.query.done;
   
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getPublishingLang: "library/publishingLang",
      getTrainingAreas: "library/trainingAreas",
      getLevel: "library/getLevelAcademic",
    }),
    userId() {
      return this.$root.$data.userId;
    },
  },
  methods: {
    ...mapActions({
      updateVacancyState: "vacancy/updateVacancy",
    }),
    formatDate(date) {
      if (date) {
        const d1 = new Date(date);
        const d2 = new Date();
        if (d1 < d2) {
          return this.expired(this.vacancy.locale); //+this.vacancy.locale
        } else {
          moment.locale(this.vacancy.locale);
          return (
            this.expiredAt(this.vacancy.locale) + moment(date).format("LL")
          );
        }
      }
      return "";
    },
    async apply() {
      if (
        this.verifyProfile() &&
        this.currentUser.candidate.status &&
        this.currentUser.candidate.province
      ) {
        this.isLoading = true;
        try {
          await this.$apollo.mutate({
            mutation: APPLY_MUTATION,
            variables: { vacancyId: this.vacancy.id },
          });
          this.success = this.$t("send_candidancy");
          this.showSuccess = true;
          this.appliedFor = true;
          // this.sendCandidanceNotify();
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        this.dialogAlert = true;
        if (this.currentUser.candidate.status) {
          if (this.currentUser.candidate.province === null) {
            this.completeMessage = this.$t("complete_profile").replace(
              "province",
              this.$t("complete_profile_province")
            );
          }
        } else {
          this.completeMessage = this.$t("complete_profile").replace(
            "province",
            ""
          );
        }
      }
    },
    async sendCandidanceNotify() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: SEND_CANDIDANCE_NOTIFICATION,
          variables: {
            candidateId: this.currentUser.candidate.id,
            entity: this.vacancy.entity.id,
            idType: this.vacancy.id,
            type: "Candidatura",
            entityName: this.vacancy.entity.name
              ? this.vacancy.entity.name
              : "Instituicao",
            date: moment().format("LL"),
            description: `Submeteu sua candidatura para ${this.vacancy.title}`,
          },
        });
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    closeAlert() {
      this.dialogAlert = false;
      this.$router.push("/candidate/candidate/profile");
    },
    getCourse(course) {
      if (course) {
        const areas = this.getTrainingAreas(this.vacancy.locale);
        const index = areas.findIndex((a) => a.key == course);
        return areas[index].text;
      } else {
        return null;
      }
    },
    verifyProfile() {
      let countFieldCand = 0,
        countField = 0;

      for (const field in this.fields) {
        countField += 1;
        var type = typeof this.currentUser.candidate[field];
        if (type === "string") {
          if (this.currentUser.candidate[field] !== "") {
            countFieldCand += 1;
          }
        } else if (
          this.currentUser.candidate[field] &&
          this.currentUser.candidate[field].length > 0
        ) {
          countFieldCand += 1;
        }
      }

      if (countFieldCand === countField) {
        return true;
      } else {
        return false;
      }
    },
    expiredAt(locale) {
      if (locale === "pt") {
        return "Expira a ";
      } else {
        return "Expire at ";
      }
    },
    expired(locale) {
      if (locale === "pt") {
        return "Expirado ";
      } else {
        return "Expired ";
      }
    },
    getCourseLevel(level) {
      const levels = this.getLevel(this.vacancy.locale);
      const index = levels.findIndex((a) => a.key == level);
      console.log("index", index);
      if (index > -1) {
        if (levels[index].text) {
          return levels[index].text;
        } else {
          return level;
        }
      } else {
        return level;
      }
    },
    go() {
      this.$router.push({
        path: `/candidate/candidate/profile`,
      });
    },
    getLanguageLevel(level) {
      const levels = this.getPublishingLang(
        this.vacancy.locale
      ).levelLanguageOptions1;
      const index = levels.findIndex((a) => a.key == level);
      if (index > -1) {
        if (levels[index].text) {
          return levels[index].text;
        } else {
          return level;
        }
      } else {
        return level;
      }
    },
  },
};
</script>